.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

@charset "UTF-8";
.intl-tel-input {
  position: relative;
  display: inline-block;
}
.intl-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.intl-tel-input .hide {
  display: none;
}
.intl-tel-input .v-hide {
  visibility: hidden;
}
.intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.intl-tel-input .flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.intl-tel-input .flag-container .arrow {
  font-size: 6px;
  margin-left: 5px;
}
.intl-tel-input .flag-container .arrow.up:after {
  content: "\25B2";
}
.intl-tel-input .flag-container .arrow.down:after {
  content: "\25BC";
}
.intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.intl-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}
.intl-tel-input .country-list.dropup {
  bottom: 100%;
  margin-bottom: -1px;
}
.intl-tel-input .country-list .flag-box {
  display: inline-block;
  width: 20px;
}
@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}
.intl-tel-input .country-list .country {
  padding: 5px 10px;
}
.intl-tel-input .country-list .country .dial-code {
  color: #999;
}
.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}
.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
  margin-right: 6px;
}
.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.intl-tel-input.allow-dropdown .flag-container {
  right: auto;
  left: 0;
  width: 100%;
}
.intl-tel-input.allow-dropdown .selected-flag {
  width: 46px;
}
.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}
.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: transparent;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  display: table;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type=tel] {
  padding-left: 76px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 70px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel] {
  padding-left: 84px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 78px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type=tel] {
  padding-left: 92px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 86px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type=tel] {
  padding-left: 100px;
}
.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 94px;
}
.intl-tel-input.iti-container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}
.iti-flag.be {
  width: 18px;
}
.iti-flag.ch {
  width: 15px;
}
.iti-flag.mc {
  width: 19px;
}
.iti-flag.ne {
  width: 18px;
}
.iti-flag.np {
  width: 13px;
}
.iti-flag.va {
  width: 15px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}
.iti-flag.ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti-flag.ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti-flag.ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti-flag.af {
  height: 14px;
  background-position: -66px 0px;
}
.iti-flag.ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti-flag.ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti-flag.al {
  height: 15px;
  background-position: -132px 0px;
}
.iti-flag.am {
  height: 10px;
  background-position: -154px 0px;
}
.iti-flag.ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti-flag.aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti-flag.ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti-flag.as {
  height: 10px;
  background-position: -242px 0px;
}
.iti-flag.at {
  height: 14px;
  background-position: -264px 0px;
}
.iti-flag.au {
  height: 10px;
  background-position: -286px 0px;
}
.iti-flag.aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti-flag.ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti-flag.az {
  height: 10px;
  background-position: -352px 0px;
}
.iti-flag.ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti-flag.bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti-flag.bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti-flag.be {
  height: 15px;
  background-position: -440px 0px;
}
.iti-flag.bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti-flag.bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti-flag.bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti-flag.bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti-flag.bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti-flag.bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti-flag.bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti-flag.bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti-flag.bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti-flag.bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti-flag.br {
  height: 14px;
  background-position: -680px 0px;
}
.iti-flag.bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti-flag.bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti-flag.bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti-flag.bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti-flag.by {
  height: 10px;
  background-position: -790px 0px;
}
.iti-flag.bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti-flag.ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti-flag.cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti-flag.cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti-flag.cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti-flag.cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti-flag.ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti-flag.ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti-flag.ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti-flag.co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti-flag.cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti-flag.de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti-flag.do {
  height: 13px;
  background-position: -1379px 0px;
}
.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti-flag.er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti-flag.es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti-flag.et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti-flag.id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti-flag.il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti-flag.im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti-flag.in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti-flag.io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti-flag.is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti-flag.it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti-flag.je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti-flag.km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti-flag.la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti-flag.li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti-flag.md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti-flag.me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti-flag.my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti-flag.na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti-flag.no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti-flag.np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti-flag.om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti-flag.py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti-flag.re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti-flag.se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti-flag.si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti-flag.so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti-flag.st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti-flag.td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti-flag.th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti-flag.to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti-flag.um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti-flag.us {
  height: 11px;
  background-position: -5241px 0px;
}
.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0px;
}
.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0px;
}
.iti-flag.va {
  height: 15px;
  background-position: -5307px 0px;
}
.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0px;
}
.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0px;
}
.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0px;
}
.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0px;
}
.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0px;
}
.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0px;
}
.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0px;
}
.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0px;
}
.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0px;
}
.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0px;
}
.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0px;
}
.iti-flag.za {
  height: 14px;
  background-position: -5566px 0px;
}
.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0px;
}
.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0px;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url(/static/media/flags.5c58d0e1.png);
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url(/static/media/flags@2x.27670c53.png);
  }
}

.iti-flag.np {
  background-color: transparent;
}

/*# sourceMappingURL=main.css.map */

@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #265985;
}
.rbc-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " \BB";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "\AB   ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #DDD;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
.switch{border:1px solid #ccc;width:50px;height:26px;border-radius:13px;cursor:pointer;display:inline-block}.switch-toggle{border:1px solid #999;box-shadow:1px 1px 1px #ccc;width:25px;height:24px;left:0;border-radius:12px;background:#fff;position:relative;transition:left .2s ease-in-out}.switch.on{background:green}.switch.on .switch-toggle{left:23px}.switch.disabled{cursor:not-allowed}
.rc-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.rc-drawer-sidebar {
  z-index: 2;
  position: absolute;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  will-change: transform;
  overflow-y: auto;
}
.rc-drawer-draghandle {
  z-index: 1;
  position: absolute;
  background-color: rgba(50, 50, 50, 0.1);
}
.rc-drawer-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
}
.rc-drawer-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out;
}
.rc-drawer.rc-drawer-left .rc-drawer-sidebar,
.rc-drawer.rc-drawer-right .rc-drawer-sidebar,
.rc-drawer.rc-drawer-left .rc-drawer-draghandle,
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  top: 0;
  bottom: 0;
}
.rc-drawer.rc-drawer-left .rc-drawer-draghandle,
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  width: 20px;
  height: 100%;
}
.rc-drawer.rc-drawer-top .rc-drawer-sidebar,
.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar,
.rc-drawer.rc-drawer-top .rc-drawer-draghandle,
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  left: 0;
  right: 0;
}
.rc-drawer.rc-drawer-top .rc-drawer-draghandle,
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  width: 100%;
  height: 20px;
}
.rc-drawer.rc-drawer-left .rc-drawer-sidebar {
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-left .rc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-left .rc-drawer-draghandle {
  left: 0;
}
.rc-drawer.rc-drawer-right .rc-drawer-sidebar {
  right: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-right .rc-drawer-sidebar {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  right: 0;
}
.rc-drawer.rc-drawer-top .rc-drawer-sidebar {
  top: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-top .rc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-top .rc-drawer-draghandle {
  top: 0;
}
.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar {
  bottom: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar {
  box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  bottom: 0;
}

.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.rc-time-picker * {
  box-sizing: border-box;
}

.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
}

.rc-time-picker-clear-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 20px;
  width: 20px;
  transition: color 0.3s ease;
}

.rc-time-picker-clear-icon:hover:after {
  color: #666;
}

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.rc-time-picker-panel-narrow {
  max-width: 113px;
}

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
}

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
  border-right: 0;
}

.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.rc-time-picker-panel-select li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
